import * as React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AnchorsWrapper from "../../components/anchorsWrapper"
import Breadcrumb from "../../components/breadcrumb"

const IndexPage = () => (
  <Layout year="2021" url="/2021">
    <SEO 
        title="L'Observatoire de l'impact positif de Big Youth et Occurrence - 2021"
        description="Découvrez le premier Observatoire de l'impact positif de Big Youth et comment les sites de marques mènent une approche vertueuse et responsable."
        path="/2021"
        year="2021" />

    <Breadcrumb label="Accueil" url="/2021" type="hp" year="2021" />

    <AnchorsWrapper year="2021" />
  </Layout>
)

export default IndexPage
